// OBJETO GLOBAL FORM VARIABLES
const formData = {
  nombre: '',
  telefono: '',
  correo: '',
  origenUTM: '',
  urlInicial: '',
  urlHistorial: '',
  reCaptchaResponse: ''
}


// INPUTS QUERY SELECTOR
const nombre = document.querySelector('#nombre');
const correo = document.querySelector('#correo');
const telefono = document.querySelector('#telefono');

const origenUTM = document.querySelector('#origenUTM');
const urlInicial = document.querySelector('#urlInicial');
const urlHistorial = document.querySelector('#urlHistorial');

// Form
const formularioContacto = document.querySelector("#form-contacto");

// Subtmit Btn
const submitBtn = document.querySelector('#submit-btn');


const asignarFormData = (dataVar, dataItem) => {
  dataVar.addEventListener('input', (event) => {
    const value = event.target.value;

    if(dataItem === 'nombre'){
      formData.nombre = value;
    }
    else if(dataItem === 'correo'){
      formData.correo = value;
    }
    else if(dataItem === 'telefono'){
      formData.telefono = value;
    }
    else if(dataItem === 'origenUTM'){
      formData.origenUTM = value;
    }
    else if(dataItem === 'urlInicial'){
      formData.urlInicial = value;
    }
    else if(dataItem === 'urlHistorial'){
      formData.urlHistorial = value;
    }
    else{
      return;
    }

    // console.log(formData);
  });

  const asignacionDirecta = () => {
    if(dataItem === 'origenUTM'){
      formData.origenUTM = dataVar.value;
    }
    else if(dataItem === 'urlInicial'){
      formData.urlInicial = dataVar.value;
    }
    else if(dataItem === 'urlHistorial'){
      formData.urlHistorial = dataVar.value;
    }
    else if (dataItem === 'reCaptchaResponse'){
      formData.reCaptchaResponse = dataVar.value;
    }
    else{
      return;
    }
    // console.log(formData);
  }
  asignacionDirecta();
}

asignarFormData(nombre, 'nombre');
asignarFormData(correo, 'correo');
asignarFormData(telefono, 'telefono');
asignarFormData(origenUTM, 'origenUTM');
asignarFormData(urlInicial, 'urlInicial');
asignarFormData(urlHistorial, 'urlHistorial');

// Ingresa solo numeros a los inputs de Telefono 0 -> 9
const soloNumeros = (evt) => {
  evt = (evt) ? evt : window.event;
  let charCode = (evt.which) ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

const validatePathResponse = () => {
  const pathPhp = '/includes/tracking/process-form.php';
  const pathJs = '/gracias.php';

  sendForm(pathPhp, pathJs);
  // sendForm(pathPhp);
}

// ReCaptcha
const validateRecaptcha = () => {
  const mgsRecaptcha = document.querySelector('#msgSubmitRecaptcha');

  if (grecaptcha && grecaptcha.getResponse(1).length > 0) {
    mgsRecaptcha.textContent = '';
    formData.reCaptchaResponse = grecaptcha.getResponse(1);
    // console.log(grecaptcha.getResponse(0));
    
    mgsRecaptcha.classList.remove('input-error');
    return true;
  } else {
    mgsRecaptcha.textContent = 'Confirme que no es un robot';
    // console.log(grecaptcha.getResponse(0));

    mgsRecaptcha.classList.add('input-error');
    return false;
  }
}

$("#submit-btn").mouseover(function() {
  validateRecaptcha();
});

const pristineFormWeb = () => {
  // create the pristine instance
  const pristine = new Pristine(formularioContacto);

  formularioContacto.addEventListener('submit', (event) => {
    event.preventDefault();
    
    // check if the form is valid
    const valid = pristine.validate(); // returns true or false

    if(valid && validateRecaptcha()){
      // sendForm();
      validatePathResponse();
    }
    else{
      // console.log(valid);
      return false;
    }

  });
};
pristineFormWeb();


const sendForm = (pathPhp, pathJs) => {
  // mostrarCompletarCampos('', true, padreSubmitForm);
  submitBtn.classList.add('d-none');

  $("#form-contacto").addClass("animacion-form");
  if($("#form-contacto").hasClass("animacion-form")) {
    setTimeout(() => { 
      $("#form-contacto").addClass("d-none");
      // $('#ancla-seccion-form').scrollTop(0);
      $("#procesando-datos").removeClass("d-none");
      $("#procesando-datos").addClass("d-flex suavizar-opacidad");
    }, 700);
  }
  else{
    $("#form-contacto").removeClass("animacion-form");
    $("#form-contacto").removeClass("d-none");
  }

  // postData(formData, pathPhp, pathJs);
  postData(formData, pathPhp, pathJs);
} 

// async function postData(formattedFormData, pathPhp, pathJs){
async function postData(formattedFormData, pathPhp, pathJs){
  const response = await fetch(pathPhp, {
    method: 'POST',
    body: JSON.stringify(formattedFormData)
  });

  const data = await response.text();
  console.log(data);
  // const data = await response.json();

  //This should later print out the values submitted through the form
  // console.log(data);
  if (data === "Completado") {
    formularioContacto.reset();
    window.location.href = pathJs;
  } else {
    console.log('Algo salio muy mal :(');
  }
}
